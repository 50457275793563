import {
	FETCH_SUBSCRIPTION, UPDATE_SUBSCRIPTION, UPDATE_SHIPPING_ADDRESS, CHANGE_ORDER_STATUS,
	TOGGLE_SUBS_CART, LIST_SCHEDULED_ORDERS, TOGGLE_UPDATING_SUB_PRODUCT, TOGGLE_RESCHEDULE_MODAL,
	LIST_PAYMENT_METHODS, CHANGE_PAYMENT_METHOD, LOGOUT, TOGGLE_SCHEDULED_ORDERS_MODAL,
	EDIT_LINE_ITEM, REMOVE_LINE_ITEM, CREATE_LINE_ITEM, UPDATE_LINE_TO_CREATE, TOGGLE_UNAVAILABLE_ITEMS,
	TOGGLE_STICKY_CART, TOGGLE_SAVE_LOADING, CHANGE_NEXT_BILLING_DATE, UPDATE_DELIVERY_FREQUENCY,
	SET_COMMITMENT_DISCOUNT
} from "utils/action-types";

const initialState = {
	subscriptionId: null,
	discounts: [],
	totalLineItemPrice: 0,
	totalQuantity: 0,
	totalLineItemDiscountedPrice: 0,
	deliveryPrice: 0,
	status: "",
	nextBillingDate: null,
	nextBillingDateEpoch: null,
	paymentMethodId: null,
	deliveryPolicy: {},
	shippingAddress: null,
	lines: {},
	paymentMethods: [],
	isActive: false,
	isCartOpen: false,
	scheduledOrders: {},
	isScheduledOrdersModalOpen: false,
	isRescheduleModalOpen: false,
	lines_to_update: {},
	lines_to_remove: {},
	lines_to_create: {},
	pendingChanges: false,
	isFetched: false,
	unavailable_items: [],
	isStickyCartOpen: false,
	saveInProgress: false,
	isSkipDisabled: false,
	chargeOffsetDays: 0,
	commitmentBasedDiscount: null,
};

const calculateTotalQuantity = (lineItems) => (
	Object.values(lineItems).reduce((acc, item) =>
		acc + (item.isDuplicated ? item.totalQuantity : item.quantity) * (item.weightInGrams >= 1000 ?
			item.weightInGrams / 1000 :
			item.weightInGrams === 1 ? 0 : item.weightInGrams), 0)
);

const hasPendingChanges = (linesToCreate, linesToUpdate, linesToRemove) => (
	Object.keys(linesToCreate).length > 0 ||
	Object.keys(linesToUpdate).length > 0 ||
	Object.keys(linesToRemove).length > 0
);

const subscriptionReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_SUBSCRIPTION:
			return {
				...state,
				...action.payload,
				lines_to_create: {},
				lines_to_update: {},
				lines_to_remove: {},
				pendingChanges: false,
				isFetched: true,
			};

		case UPDATE_SUBSCRIPTION:
			return {
				...state,
				...action.payload,
				isActive: action.payload.status === "ACTIVE",
			};

		case CHANGE_NEXT_BILLING_DATE:
			return {
				...state,
				nextBillingDate: action.payload.nextBillingDate,
				nextBillingDateEpoch: action.payload.nextBillingDateEpoch,
			};

		case CHANGE_ORDER_STATUS: {
			const scheduledOrders = {
				...state.scheduledOrders,
				[action.payload.orderId]: {
					...state.scheduledOrders[action.payload.orderId],
					status: action.payload.status
				},
			};
			const isSkipDisabled = Object.values(scheduledOrders).filter(order => order.status === "SKIPPED").length >= 2;
			return {
				...state,
				scheduledOrders,
				isSkipDisabled
			};
		}


		case UPDATE_DELIVERY_FREQUENCY:
			return {
				...state,
				deliveryPolicy: action.payload,
			};

		case LIST_SCHEDULED_ORDERS:
			return {
				...state,
				scheduledOrders: Object.fromEntries(action.payload.orders.map(order => [order.id, order])),
				isSkipDisabled: action.payload.orders.filter(order => order.status === "SKIPPED").length >= 2
			};

		case UPDATE_SHIPPING_ADDRESS:
			return {
				...state,
				shippingAddress: action.payload
			};

		case LIST_PAYMENT_METHODS:
			return {
				...state,
				paymentMethods: action.payload
			};

		case CHANGE_PAYMENT_METHOD:
			return {
				...state,
				paymentMethodId: action.payload
			};

		case TOGGLE_SUBS_CART:
			return {
				...state,
				isCartOpen: !state.isCartOpen
			};

		case TOGGLE_SCHEDULED_ORDERS_MODAL:
			return {
				...state,
				isScheduledOrdersModalOpen: !state.isScheduledOrdersModalOpen
			};

		case TOGGLE_RESCHEDULE_MODAL:
			return {
				...state,
				isRescheduleModalOpen: !state.isRescheduleModalOpen
			};

		case TOGGLE_UPDATING_SUB_PRODUCT:
			return {
				...state,
				updating: !state.updating,
				lines: {
					...state.lines,
					[action.productVariantId]: {
						...state.lines[action.productVariantId],
						updating: !state.lines[action.productVariantId]?.updating,
						stock: action.stock,
					}
				}
			};

		case TOGGLE_UNAVAILABLE_ITEMS:
			return {
				...state,
				unavailable_items: action.payload
			};

		case TOGGLE_STICKY_CART: {
			return {
				...state,
				isStickyCartOpen: action.payload
			};
		}

		case SET_COMMITMENT_DISCOUNT: {
			return {
				...state,
				commitmentBasedDiscount: action.payload
			};
		}

		case TOGGLE_SAVE_LOADING:
			return {
				...state,
				saveInProgress: !state.saveInProgress
			};

		case LOGOUT:
			return initialState;

		case EDIT_LINE_ITEM: {
			const { lineItemId, modifiedQuantity, variantShopifyId, isDuplicated, modifiedTotalQuantity } = action.payload;

			const updatedLineItems = {
				...state.lines,
				[variantShopifyId]: {
					...state.lines[variantShopifyId],
					quantity: isDuplicated ? modifiedQuantity : modifiedTotalQuantity,
					...(isDuplicated && { totalQuantity: modifiedTotalQuantity }),
				},
			};

			const updatedLinesToUpdate = {
				...state.lines_to_update,
				[lineItemId]: { lineId: lineItemId, quantity: isDuplicated ? modifiedQuantity : modifiedTotalQuantity }
			};

			if (state.lines_to_remove[lineItemId])
				delete updatedLinesToUpdate[lineItemId];

			let quantitiesUnchanged = true;

			// Check if quantities of all updated items are the same as their original quantities
			Object.values(updatedLineItems).forEach(item => {
				if ((isDuplicated ? item.totalQuantity : item?.quantity) !== state.lines[item.variantShopifyId].totalQuantity) {
					quantitiesUnchanged = false;
				}
			});

			return {
				...state,
				lines: updatedLineItems,
				lines_to_update: updatedLinesToUpdate,
				pendingChanges: !quantitiesUnchanged && hasPendingChanges(state.lines_to_create, updatedLinesToUpdate, state.lines_to_remove),
				totalQuantity: calculateTotalQuantity(updatedLineItems)
			};
		}

		case REMOVE_LINE_ITEM: {
			const { lineItemId, variantShopifyId, isDuplicated, modifiedQuantity, modifiedTotalQuantity } = action.payload;
			const newLineItems = { ...state.lines };

			let originalItem = state.lines[variantShopifyId];

			// If the item is duplicated and the quantity is 0, and total quantity is greater than 0 which means there are more duplicates
			// then we need to remove the first duplicate and update the original item with the first duplicate
			if (isDuplicated && modifiedTotalQuantity > 0 && modifiedQuantity === 0) {
				const firstDuplicate = originalItem.duplicates.shift();
				originalItem = { ...originalItem, ...firstDuplicate, totalQuantity: modifiedTotalQuantity };
				newLineItems[variantShopifyId] = originalItem;
			} else
				delete newLineItems[variantShopifyId];

			const updatedLinesToRemove = {
				...state.lines_to_remove,
				[lineItemId]: { lineId: lineItemId }
			};

			const updatedLinesToUpdate = { ...state.lines_to_update };
			delete updatedLinesToUpdate[lineItemId];

			return {
				...state,
				lines: newLineItems,
				lines_to_remove: updatedLinesToRemove,
				lines_to_update: updatedLinesToUpdate,
				pendingChanges: hasPendingChanges(state.lines_to_create, state.lines_to_update, updatedLinesToRemove),
				totalQuantity: calculateTotalQuantity(newLineItems)
			};
		}


		case CREATE_LINE_ITEM: {
			const { variantShopifyId } = action.payload;

			const newLinesToCreate = {
				...state.lines_to_create,
				[variantShopifyId]: {
					...action?.payload,
					quantity: 1
				}
			};

			const combinedLineItems = {
				...state.lines,
				[variantShopifyId]: {
					...(state.lines[variantShopifyId] || {}),
					...newLinesToCreate[variantShopifyId],
					quantity: (state.lines[variantShopifyId]?.quantity || 0) + 1,
				}
			};

			return {
				...state,
				lines_to_create: newLinesToCreate,
				lines: combinedLineItems,
				pendingChanges: hasPendingChanges(newLinesToCreate, state.lines_to_update, state.lines_to_remove),
				totalQuantity: calculateTotalQuantity(combinedLineItems)
			};
		}

		case UPDATE_LINE_TO_CREATE: {
			const updatedLinesToCreate = { ...state.lines_to_create };
			const variantShopifyId = action.payload.item.variantShopifyId;

			if (action.payload.item.quantity <= 0)
				delete updatedLinesToCreate[variantShopifyId];
			else
				updatedLinesToCreate[variantShopifyId] = action.payload.item;


			const updatedLineItems = { ...state.lines };

			if (!updatedLinesToCreate[variantShopifyId])
				delete updatedLineItems[variantShopifyId];
			else
				updatedLineItems[variantShopifyId] = updatedLinesToCreate[variantShopifyId];


			return {
				...state,
				lines_to_create: updatedLinesToCreate,
				lines: updatedLineItems,
				pendingChanges: hasPendingChanges(updatedLinesToCreate, state.lines_to_update, state.lines_to_remove),
				totalQuantity: calculateTotalQuantity(updatedLineItems)
			};
		}

		default:
			return state;
	}
};

export default subscriptionReducer;
